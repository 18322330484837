import Joi, { string } from 'joi';
import _ from 'lodash';
import i18next from 'i18next';
import { FormErrorType, ValidateFormDataFunctionType } from '../Types/FormUtilsTypes';

class FormUtilsServices {
  getMessageTranslateKey = (
      appName:string,
      pageName: string,
      formName?: string,
  ) => {
    if (!_.isUndefined(formName)) {
      return `${appName}:${pageName}.${formName}.form.errors`;
    }

    return `${appName}:${pageName}.form.errors`;
  };

  validateFormData = ({
    data,
    schema,
    messageTranslateKey,
    alsoValidateEmptyData = false,
    onValidateSuccess,
  } : ValidateFormDataFunctionType) => {
    if (!_.isEmpty(data) || alsoValidateEmptyData) {
      const validateResult = schema.validate(data);

      if (_.isNull(validateResult)) {
        return null;
      }

      let errors: FormErrorType = {};

      validateResult?.error?.details?.map((error: any) => {
        const attributeKey = error.path[0];
        const processedErrorArray = i18next.t(
          `${messageTranslateKey}.${error.path[0]}.${error.type}`,
          { context: error.context },
        );
        errors[attributeKey] = [];
        errors[attributeKey].push(processedErrorArray);
      });

      return onValidateSuccess?.(errors);
    }

    return onValidateSuccess?.(null);
  };

  emailRule = () => Joi.string().email({ tlds: { allow: false } }).min(1).max(550);

  stringRule = () => Joi.string().max(255);

  textRule = () => Joi.string().max(65535);

  otpRule = () => Joi.string().max(6);

  booleanRule = () => Joi.boolean();

  integerRule = () => Joi.number().integer().min(0);

  numberRule = () => Joi.number();
}

export default new FormUtilsServices();
