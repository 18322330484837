export interface APILoadingStatus {
    formKey: string | undefined,
    API: string | undefined,
    loadingStatus: boolean,
}

export interface LoadingType {
    enable: boolean,
    progress: number,
    totalEnableRequest: number,
    APILoadingStatuses: APILoadingStatus[],
}

export const LOADING_STATE_NAME = 'loading';
