import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {APPLICATION_STATE_NAME, ApplicationObjectType, ApplicationStateType} from '../Types/ApplicationTypes';

const initialState: ApplicationStateType = {
    current_app_data: {} as ApplicationObjectType,
};

export const applicationSlice = createSlice({
    name: APPLICATION_STATE_NAME,
    initialState,
    reducers: {
        setCurrentApplicationData: (state, action: PayloadAction<ApplicationObjectType>) => {
            state.current_app_data = action.payload;
        },
    },
});

export const {setCurrentApplicationData} = applicationSlice.actions;

export default applicationSlice.reducer;
