import _ from "lodash";
import CookiesServices from "../../CookiesPackage/Services/CookiesServices";
import {FOOTER_DATA_COOKIES_NAME, FooterDataObjectType} from "../Types/FooterDataTypes";

class FooterServices {
    beenHaveFooterDataAtCookie = () => CookiesServices.has(FOOTER_DATA_COOKIES_NAME);

    getFooterDataAtCookie = () => JSON.parse(CookiesServices.get(FOOTER_DATA_COOKIES_NAME));

    isEmptyFooterDataAtCookie = () => {
        const footerData = this.getFooterDataAtCookie();

        return _.isEmpty(footerData) || _.isUndefined(footerData);
    }

    setFooterDataAtCookie = (data: FooterDataObjectType) => CookiesServices.set(
        FOOTER_DATA_COOKIES_NAME,
        JSON.stringify(data),
        1440
    );

    upsertFooterDataAtCookie = (data: FooterDataObjectType) => {
        if (data.must_update_data) {
            this.setFooterDataAtCookie(data);

            return data;
        } else {
            if (this.beenHaveFooterDataAtCookie()) {
                return this.getFooterDataAtCookie();
            }

            return data;
        }
    }
}

export default new FooterServices();
