import moment, { Moment } from 'moment-timezone';
import {DurationInputArg1, DurationInputArg2, MomentInput} from 'moment/moment';
import MultipleLanguageService from '../../MultipleLanguagePackage/Services/MultipleLanguageService';

class TimeServices {
  parseUTC = (utcTimeString: MomentInput) => moment.utc(utcTimeString).locale(MultipleLanguageService.getCurrentLanguage());

  currentTimeZone = (utcTimeString: MomentInput) => moment.utc(utcTimeString)
      .locale(MultipleLanguageService.getCurrentLanguage())
      .local();

  currentUTCTime = () => moment.utc().locale(MultipleLanguageService.getCurrentLanguage());

  renderCurrentUTCTime = (format = 'LL') => moment.utc().locale(MultipleLanguageService.getCurrentLanguage()).format(format);

  getCurrentUTCTimeAtISOString = () => this.currentUTCTime().toISOString();

  getCurrentUTCTimeToSendToBackend = () => this.currentUTCTime().toISOString();

  renderCurrentTimeZone = (utcTimeString: MomentInput, format = 'LL') => this.currentTimeZone(utcTimeString)
      .format(format);

  renderUTC = (utcTimeString: MomentInput, format = 'LL') => this.parseUTC(utcTimeString).format(format);

  renderUTCFromNow = (utcTimeString: MomentInput) => this.parseUTC(utcTimeString).fromNow();

  getCurrentUserTimeZone = () => moment.tz.guess();

  renderCurrentYearByCurrentUserTimeZone = () => moment.tz(this.getCurrentUserTimeZone())
    .locale(MultipleLanguageService.getCurrentLanguage())
    .format('YYYY');

  getFutureUTCTime = (amount?: DurationInputArg1, unit?: DurationInputArg2) => this.currentUTCTime().add(amount, unit);

  getFutureTimeByTotalMinutesFromCurrentUTCTime = (minutes: number) => this.getFutureUTCTime(minutes, 'minute');

  getFutureTimeByTotalSecondsFromCurrentUTCTime = (seconds: number) => this.getFutureUTCTime(seconds, 'minute');

  durationBetweenTwoTime = (
    timeOne: Moment,
    timeTwo: Moment,
  ) => timeOne?.diff(timeTwo, 'minutes');

  isPast = (time: Moment) => time.isBefore(this.currentUTCTime());

  isFuture = (time: Moment) => time.isAfter(this.currentUTCTime());
}

export default new TimeServices();
