import {configureStore, ThunkAction, Action} from '@reduxjs/toolkit';
import {USER_WALLET_STATE_NAME} from "./Packages/WalletPackage/Types/UserWalletTypes";
import userWalletReducer from "./Packages/WalletPackage/Slice/userWalletSlice";
import {CONFIGURATION_STATE_NAME} from './Packages/ConfigurationPackage/Types/ConfigurationTypes';
import configurationReducer from './Packages/ConfigurationPackage/Slice/configurationSlice';
import {AUTHENTICATION_STATE_NAME} from './Packages/AuthenticationPackage/Types/AuthenticationTypes';
import authenticationReducer from './Packages/AuthenticationPackage/Slice/authenticationSlice';
import {APPLICATION_STATE_NAME} from './Packages/ApplicationManagementPackage/Types/ApplicationTypes';
import applicationReducer from './Packages/ApplicationManagementPackage/Slice/applicationSlice';
import {FORM_ERROR_STATE_NAME} from './Packages/FormUtilsPackage/Types/FormUtilsTypes';
import formErrorReducer from './Packages/FormUtilsPackage/Slice/formErrorSlice';
import errorReducer from './Packages/ErrorPackage/Slice/errorSlice';
import {ERROR_STATE_NAME} from './Packages/ErrorPackage/Types/utilsTypes';
import {LOADING_STATE_NAME} from './Packages/LoadingPackage/Types/loadingTypes';
import loadingReducer from './Packages/LoadingPackage/Slice/loadingSlice';
import {USER_MANAGEMENT_STATE_NAME} from './Packages/UserManagementPackage/Types/UserManagementTypes';
import userManagementReducer from './Packages/UserManagementPackage/Slice/userManagementSlice';
import {PROFILE_MANAGEMENT_STATE_NAME} from "./Packages/UserManagementPackage/Types/ProfileManagementTypes";
import profileManagementReducer from './Packages/UserManagementPackage/Slice/profileManagementSlice';
import {MODAL_STATE_NAME} from "./Packages/UIComponentsPackage/Types/ModalTypes";
import modalReducer from './Packages/UIComponentsPackage/Slice/modalSlice';
import {USER_NAMES_MANAGEMENT_STATE_NAME} from "./Packages/UserNamesManagementPackage/Types/UserNameManagementTypes";
import userNamesReducer from './Packages/UserNamesManagementPackage/Slice/userNameManagementSlice';
import {MAINTENANCE_STATE_NAME} from "./Packages/MaintenancePackage/Types/MaintenanceTypes";
import maintenanceReducer from "./Packages/MaintenancePackage/Slice/maintenanceSlice";

export const store = configureStore({
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: {
            ignoredActions: [],
            ignoredActionPaths: [],
            ignoredPaths: [],
        },
    }),
    reducer: {
        [APPLICATION_STATE_NAME]: applicationReducer,
        [CONFIGURATION_STATE_NAME]: configurationReducer,
        [AUTHENTICATION_STATE_NAME]: authenticationReducer,
        [FORM_ERROR_STATE_NAME]: formErrorReducer,
        [ERROR_STATE_NAME]: errorReducer,
        [LOADING_STATE_NAME]: loadingReducer,
        [USER_MANAGEMENT_STATE_NAME]: userManagementReducer,
        [PROFILE_MANAGEMENT_STATE_NAME]: profileManagementReducer,
        [MODAL_STATE_NAME]: modalReducer,
        [USER_NAMES_MANAGEMENT_STATE_NAME]: userNamesReducer,
        [MAINTENANCE_STATE_NAME]: maintenanceReducer,
        [USER_WALLET_STATE_NAME]: userWalletReducer,
    },
    // TODO: We have an config at BE to detect FE is a DevMode or not, try to use it.
    devTools: true,
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType,
    RootState,
    unknown,
    Action<string>>;
