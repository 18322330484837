import {APILoadingStatus} from '../Types/loadingTypes';
import _ from 'lodash';

class LoadingServices {
    /**
     * @param API
     * @param APILoadingStatuses
     * @param formKey
     */
    getAPILoadingStatusIndex = (
        APILoadingStatuses: APILoadingStatus[],
        formKey?: string,
        API?: string,
    ) => {
        if (!_.isUndefined(formKey) && _.isArray(APILoadingStatuses)) {
            let index = APILoadingStatuses.findIndex((item) => item.formKey === formKey);

            if (index > -1) {
                return index;
            }
        }

        if (!_.isUndefined(API) && _.isArray(APILoadingStatuses)) {
            let index = APILoadingStatuses.findIndex((item) => item.API === API);

            if (index > -1) {
                return index;
            }
        }

        return -1;
    }

    /**
     * @param API
     * @param APILoadingStatuses
     * @param formKey
     */
    getAPILoadingStatus = (
        APILoadingStatuses: APILoadingStatus[],
        formKey?: string,
        API?: string,
    ) => {
        let index = this.getAPILoadingStatusIndex(APILoadingStatuses, formKey, API);

        if (index > -1) {
            return APILoadingStatuses[index].loadingStatus;
        }

        return false;
    }
}

export default new LoadingServices();
