import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {MAINTENANCE_STATE_NAME, MaintenanceStateObjectType, MaintenanceStateType} from "../Types/MaintenanceTypes";

const initialState: MaintenanceStateType = {
  data: {} as MaintenanceStateObjectType,
};

export const maintenanceSlice = createSlice({
  name: MAINTENANCE_STATE_NAME,
  initialState,
  reducers: {
    setMaintenanceData: (state, action: PayloadAction<MaintenanceStateObjectType>) => {
      state.data = action.payload;
    },
  },
});

export const { setMaintenanceData } = maintenanceSlice.actions;

export default maintenanceSlice.reducer;
