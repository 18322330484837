import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {
    USER_WALLET_STATE_NAME,
    UserWalletObjectType,
    UserWalletSimpleObject,
    UserWalletStateType
} from "../Types/UserWalletTypes";

const initialState: UserWalletStateType = {
    data: [] as UserWalletStateType['data'],
    simple_data: [] as UserWalletStateType['simple_data'],
};

/**
 * @param data
 */
const buildSimpleData = (data: UserWalletStateType['data']) => {
    let userWalletSimpleData = [] as UserWalletStateType['simple_data'];

    data.map((value, index) => {
        userWalletSimpleData.push({
            [value.uuid as any]: {
                amount: value.amount,
                money_unit: value.money_unit,
            }
        });
    });

    return userWalletSimpleData;
}

export const userWalletSlice = createSlice({
    name: USER_WALLET_STATE_NAME,
    initialState,
    reducers: {
        setUserWalletData: (
            state: { data: UserWalletStateType['data']; simple_data: UserWalletStateType['simple_data'] },
            action: PayloadAction<UserWalletStateType['data']>
        ) => {
            state.data = action.payload;

            state.simple_data = buildSimpleData(action.payload);
        },
        setUserWalletSimpleData: (
            state: { simple_data: UserWalletStateType['simple_data']; },
            action: PayloadAction<UserWalletStateType['simple_data']>
        ) => {
            state.simple_data = action.payload;
        },
        addUserWalletSimpleData: (
            state: { simple_data: UserWalletStateType['simple_data']; },
            action: PayloadAction<UserWalletSimpleObject>
        ) => {
            state.simple_data.push(action.payload);
        },
        updateOneUserWalletDataItem: (
            state: { data: UserWalletStateType['data'], simple_data: UserWalletStateType['simple_data'] },
            action: PayloadAction<UserWalletObjectType>
        ) => {
            const index = state.data.findIndex((userWallet) => userWallet.uuid === action.payload.uuid);
            let newData = state.data;

            newData[index] = action.payload;

            state.simple_data = buildSimpleData(newData);

            state.data = newData;
        },
    },
});

export const {
    setUserWalletData,
    setUserWalletSimpleData,
    addUserWalletSimpleData,
    updateOneUserWalletDataItem,
} = userWalletSlice.actions;

export default userWalletSlice.reducer;
