import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {AUTHENTICATION_STATE_NAME, AuthenticationStateType, UserObjectType} from '../Types/AuthenticationTypes';

const initialState: AuthenticationStateType = {
    current_user_data: {} as UserObjectType,
};

export const authenticationSlice = createSlice({
    name: AUTHENTICATION_STATE_NAME,
    initialState,
    reducers: {
        setCurrentUserData: (state, action: PayloadAction<UserObjectType>) => {
            state.current_user_data = action.payload;
        },
        updateCurrentUserData: (state, action: PayloadAction<UserObjectType>) => {
            state.current_user_data = {
                ...state.current_user_data,
                ...action.payload,
            };
        },
    },
});

export const {setCurrentUserData, updateCurrentUserData} = authenticationSlice.actions;

export default authenticationSlice.reducer;
