import {MoneyUnitObjectType} from "../../SponsorPackage/Types/SponsorTypes";
import {IndexMetaType} from "../../APIManagement/Types/APIManagementTypes";

export interface UserWalletObjectType {
    uuid?: string,
    user_uuid?: string,
    amount: number,
    money_unit_uuid: string,
    money_unit: MoneyUnitObjectType,
    note: string,
}

export interface UserWalletIndexDataType extends IndexMetaType {
    data: UserWalletObjectType[],
}

export interface UserWalletSimpleObject {
    [key: string]: {
        amount: number,
        money_unit: MoneyUnitObjectType,
    }
}

export interface UserWalletStateType {
    data: UserWalletObjectType[];
    simple_data: UserWalletSimpleObject[]
}

export const USER_WALLET_STATE_NAME = 'userWallet';

export interface UserGeneralWalletObjectType {
    uuid?: string,
    total_amount?: string,
    money_unit_uuid: number,
    money_unit: MoneyUnitObjectType,
}
