import Joi from 'joi';
import {
    SponsorshipInformationObjectType
} from "../../SponsorPackage/Types/SponsorTypes";
import FormUtilsServices from '../../FormUtilsPackage/Services/FormUtilsServices';
import {
    PayPalUserWithdrawInformation,
    UserWithdrawConfigObjectType,
} from "../../WithdrawPackage/Types/UserWithdrawTypes";

export interface UserObjectType {
    uuid: string,
    email: string,
    username: string,
    phone_number: string,
    is_current_user: boolean,
    is_missing_required_profile_data: boolean,
    need_verify_phone_otp_when_login: boolean,
    need_verify_email_otp_when_login: boolean,
    user_activated_easy_to_remember_path?: {
        become_primary_at?: string,
        easy_to_remember_path?: string,
        easy_to_remember_path_uuid?: string,
        user_easy_to_remember_path_data?: {
            uuid?: string,
            user_uuid?: string,
            easy_to_remember_path?: string,
        }
    },
    user_sponsorship_config?: SponsorshipInformationObjectType,
    is_missing_profile_data: boolean,
    missing_profile_data: {
        user_activated_easy_to_remember_path: boolean,
        user_sponsorship_config: boolean,
        user_uuid?: string,
    },
    user_withdraw_config: UserWithdrawConfigObjectType,
    user_withdraw_information: PayPalUserWithdrawInformation[],
    user_data: {
        user_uuid: string,
        data: any,
    }
}

export interface AuthenticationStateType {
    current_user_data: UserObjectType;
}

export const AUTHENTICATION_STATE_NAME = 'authentication';

export const AUTH_TOKEN_COOKIES_KEY_NAME = 'unitedUniversesAT';

export const USER_DATA_LOCAL_STORAGE_KEY_NAME = 'unitedUniversesUserData';

export const USERNAME_PASSWORD_COOKIES_KEY_NAME = 'unitedUniversesITL';

export const RECOVERY_PASSWORD_KEY_COOKIES_NAME = 'unitedUniversesRPK';

export const RECOVERY_PASSWORD_USER_NAME_COOKIES_NAME = 'unitedUniversesRPU';

export interface LoginFormParams {
    email?: string,
    username: string,
    password: string,
}

export interface SendOTPToRecoveryPasswordFormParams {
    username: string,
}

export interface VerifyPrivateOTPThenLoginParams {
    key: string,
    email?: string,
    username: string,
    password: string,
}

export interface VerifyPrivateOTPThenChangePasswordParams {
    key: string,
    username: string,
    password: string,
    password_confirmation: string,
}

export const LoginFormParamsSchema = Joi.object().options({abortEarly: true}).keys({
    username: FormUtilsServices.stringRule().required(),
    password: FormUtilsServices.stringRule().required(),
});

export const RecoveryPasswordFormParamsSchema = Joi.object().options({abortEarly: true}).keys({
    username: FormUtilsServices.stringRule(),
    key: FormUtilsServices.stringRule().required(),
    password: FormUtilsServices.stringRule().required(),
    password_confirmation: FormUtilsServices.stringRule().required(),
});

export const SendOTPToRecoveryPasswordFormParamsSchema = Joi.object().options({abortEarly: true}).keys({
    username: FormUtilsServices.stringRule().required(),
});

export interface RegisterFormParams {
    username: string,
    original_username?: string,
    email: string,
    password: string,
    password_confirmation: string,
    phone_number: string,
}

export const RegisterFormParamsSchema = Joi.object().options({abortEarly: true}).keys({
    username: FormUtilsServices.stringRule().required(),
    original_username: FormUtilsServices.stringRule().required(),
    email: FormUtilsServices.emailRule().required(),
    phone_number: FormUtilsServices.stringRule().required(),
    password: FormUtilsServices.stringRule().required(),
    password_confirmation: FormUtilsServices.stringRule().required(),
});

export interface VerifyOTPObjectType {
    is_over_the_number_of_otp_emails_today: boolean,
    need_to_verify_otp: boolean,
    need_to_verify_phone_otp: boolean,
    need_to_verify_email_otp: boolean,
    need_to_verify_otp_after_register: boolean,
    need_to_verify_email_otp_after_register_success: boolean,
    need_to_verify_phone_otp_after_register_success: boolean,
}

export interface IsOverTheNumberOfOTPTodayObjectType {
    is_over_the_number_of_otp_emails_today: boolean,
}
