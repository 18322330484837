export interface ApplicationObjectType {
    current_app_code: string,
    current_planet_app_code: string,
}

export interface ApplicationStateType {
    current_app_data: ApplicationObjectType;
}

export const APPLICATION_STATE_NAME = 'application';

export const CURRENT_FRONT_END_REQUESTER_INFORMATION_PART_KEY__COOKIES_KEY = 'unitedUniversesCFERIPK';

export const DOMAINS = [
    'earth-local.authentication.uniteduniverses.org',
    'earth-local.uniteduniverses.org',
    'earth.uniteduniverses.org',
    'earth-authentication.uniteduniverses.org',
    'check-information-parts.uniteduniverses.org'
];
