export interface ConfigurationObjectType {
    default_value: string,
    display_name: string,
    identity_code: string,
    scope: number,
    scope_rendered: string,
    uuid: string,
    value: string,
    value_rendered: string|boolean|number|object|[],
    value_type: number,
}

export interface ConfigurationSimpleObject {
    [key: string]: any,
}

export interface ConfigurationStateType {
    data: ConfigurationObjectType[];
    simple_data: ConfigurationSimpleObject
}

export const CONFIGURATION_STATE_NAME = 'configuration';
