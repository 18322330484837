import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  LimitTheNumberOfUsersAnalyticsObjectType,
  USER_MANAGEMENT_STATE_NAME,
  UserManagementStateType,
} from '../Types/UserManagementTypes';

const initialState: UserManagementStateType = {
  limit_the_number_of_users: {} as LimitTheNumberOfUsersAnalyticsObjectType,
};

export const userManagementSlice = createSlice({
  name: USER_MANAGEMENT_STATE_NAME,
  initialState,
  reducers: {
    setCurrentLimitTheNumberOfUsersData: (state, action: PayloadAction<LimitTheNumberOfUsersAnalyticsObjectType>) => {
      state.limit_the_number_of_users = action.payload;
    },
  },
});

export const { setCurrentLimitTheNumberOfUsersData } = userManagementSlice.actions;

export default userManagementSlice.reducer;
