import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { APILoadingStatus, LOADING_STATE_NAME, LoadingType } from '../Types/loadingTypes';
import LoadingServices from '../Services/LoadingServices';

const initialState: LoadingType = {
  progress: 0,
  enable: false,
  totalEnableRequest: 0,
  APILoadingStatuses: [],
};

export const loadingSlice = createSlice({
  name: LOADING_STATE_NAME,
  initialState,
  reducers: {
    addAPILoadingStatus: (state, action: PayloadAction<APILoadingStatus>) => {
      let index = LoadingServices.getAPILoadingStatusIndex(
        state.APILoadingStatuses,
        action.payload.formKey,
        action.payload.API,
      );

      if (index > -1) {
        state.APILoadingStatuses[index] = action.payload;
      } else {
        state.APILoadingStatuses.push(action.payload);
      }
    },
    requestToEnableLoadingLayer: (state, action) => {
      state.enable = true;
      state.totalEnableRequest += 1;
    },
    requestToHideLoadingLayer: (state, action) => {
      if (state.totalEnableRequest === 1) {
        state.enable = false;
      }
      state.totalEnableRequest -= 1;
    },
  },
});

export const { requestToEnableLoadingLayer, requestToHideLoadingLayer, addAPILoadingStatus } = loadingSlice.actions;

export default loadingSlice.reducer;
