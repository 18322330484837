function IconWrapper({
  children,
  className = '',
}: { children: string|JSX.Element, className?: string }) {
  const renderClassName = () => {
    let result = `inline-block align-middle p-1.5 md:p-2 rounded-full`;

    return `${result} ${className}`;
  };

  return (
    <div className={renderClassName()}>
      {children}
    </div>
  );
}

export default IconWrapper;
