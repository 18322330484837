import _ from 'lodash';
import './Footer.scss';
import {useEffect, useState} from "react";
import {useTranslation} from 'react-i18next';
import {useAppSelector} from "../../../hooks";
import FooterServices from "../Services/FooterServices";
import {COMMON_APP_NAME} from "../../../ENV/UnitedUniversesENV";
import {FooterDataObjectType} from "../Types/FooterDataTypes";
import {CONFIGURATION_STATE_NAME} from '../../ConfigurationPackage/Types/ConfigurationTypes';
import {USER_MANAGEMENT_STATE_NAME} from '../../UserManagementPackage/Types/UserManagementTypes';
import ApplicationServices from '../../ApplicationManagementPackage/Services/ApplicationServices';

function Footer({ className }: { className?: string }) {
    const {t} = useTranslation([COMMON_APP_NAME]);

    const configuration = useAppSelector((state) => state[CONFIGURATION_STATE_NAME].simple_data);
    const limitTheNumberOfUsers = useAppSelector((state) => state[USER_MANAGEMENT_STATE_NAME].limit_the_number_of_users);
    const [footerData, setFooterData] = useState<FooterDataObjectType>({} as FooterDataObjectType);

    const renderClassName = () => {
        if (!_.isUndefined(className)) {
            // TODO: Change text-zinc-300 to before ${className} if tailwind rules has the change.
            return `Footer ${className}`;
        }

        return `Footer text-zinc-300`;
    }

    useEffect(() => {
        let mustUpdateData = false;
        if (!_.isUndefined(limitTheNumberOfUsers.total_users)) {
            mustUpdateData = true;
        }

        const footerData = FooterServices.upsertFooterDataAtCookie({
            total_users: limitTheNumberOfUsers.total_users,
            copyright_text: ApplicationServices.getCopyrightText(configuration.GLOBAL__APPLICATION_NAME),
            htuio_infinity_owner_forever_text: ApplicationServices.getHTUIOInfinityOwnerForeverText(),
            version_text: ApplicationServices.getVersionText(
                configuration.GLOBAL__APPLICATION_VERSION,
                configuration.GLOBAL__APPLICATION_VERSION_RELEASED_AT,
            ),
            must_update_data: mustUpdateData,
        });

        setFooterData(footerData);
    }, [limitTheNumberOfUsers, configuration]);

    return (
        <div className={renderClassName()}>
            United Universes © 2023
            <p>Founder & Owner Forever By HT U IO (Thiên Hùng).</p>
            <p>Based on founder's experiences & many hours of working time.</p>
            <p>Working, Defense, No war forever, Don't be bad</p>
            <p>Version: 1.7.0. Released on 20 March 2022.</p>
            <p>
                {t('current_total_limited_user')}
                <span>5 Users</span>
            </p>
        </div>
    );
}

export default Footer;
