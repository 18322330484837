import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ERROR_STATE_NAME, ErrorStateType } from '../Types/errorsTypes';

const initialState: ErrorStateType = {
  errors: {} as ErrorStateType['errors'],
};

export const errorSlice = createSlice({
  name: ERROR_STATE_NAME,
  initialState,
  reducers: {
    setErrors: (state, action: PayloadAction<ErrorStateType['errors']>) => {
      state.errors = action.payload;
    },
  },
});

export const { setErrors } = errorSlice.actions;

export default errorSlice.reducer;
