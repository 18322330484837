import BG from './assets/bg.webp';
import './OwnerApplicationLayout.scss';
import { BsInfoCircle } from 'react-icons/bs';
import { useTranslation } from 'react-i18next';
import Footer from '../../../../Packages/UIComponentsPackage/Layout/Footer';
import Logo from '../../../../Packages/UIComponentsPackage/Application/Logo';
import { EARTH_AUTHENTICATION_APP_NAME } from '../../../../ENV/UnitedUniversesENV';
import IconWrapper from '../../../../Packages/UIComponentsPackage/Other/IconWrapper';

function OwnerApplicationLayout({ children }: { children: JSX.Element|string }) {
  const { t } = useTranslation([EARTH_AUTHENTICATION_APP_NAME]);

  return (
    <div
      className="AuthenticationLayout"
      style={{
        backgroundImage: `url('${BG}')`,
      }}
    >
      <div className="AuthenticationLayout__MainContent">
        <div className="BlurCard">
          <div className="AuthenticationLayout__MainContent__LogoWrapper">
            <Logo className="AuthenticationLayout__MainContent__Logo" type="light" />
          </div>
          <div className="-mt-1">
            {children}
          </div>
          <Footer />
        </div>
      </div>
      <div className="AuthenticationLayout__BackgroundInfo">
        <div className="BlurCard bg-black/15">
          <div className="AuthenticationLayout__BackgroundInfo_InfoIcon">
            <IconWrapper className="bg-zinc-800">
              <BsInfoCircle fontSize={24} />
            </IconWrapper>
          </div>
          <div className="AuthenticationLayout__BackgroundInfo_InfoText">
            {/* eslint-disable-next-line react/no-unescaped-entities */}
            <div className="mb-2">"First Images from the James Webb Space Telescope".</div>
            { t('background_picture_introduce') }
            {' '}
            <div className="mt-2">
              <a href="https://www.nasa.gov/webbfirstimages" target="_blank" rel="noreferrer">
                https://www.nasa.gov/webbfirstimages
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OwnerApplicationLayout;
