import React, {
    useEffect,
    useState,
} from 'react';
import './App.scss';
import _ from 'lodash';
import {v4 as uuidv4} from 'uuid';
import {useDispatch} from "react-redux";
import {useTranslation} from "react-i18next";
import {useNavigate} from 'react-router-dom';
import ApplicationRoutes from './ApplicationRoutes';
import ApplicationCookies from "./ApplicationCookies";
import {useAppSelector} from './UnitedUniverses/hooks';
import ApplicationOnlineStatus from "./ApplicationOnlineStatus";
import {EARTH_APP_NAME, OWNER_FOREVER_APP_NAME} from "./UnitedUniverses/ENV/UnitedUniversesENV";
import {CONFIGURATION_STATE_NAME} from "./UnitedUniverses/Packages/ConfigurationPackage/Types/ConfigurationTypes";
import OwnerApplicationLayout
    from "./UnitedUniverses/OwnerForeverApplication/Modules/OwnerForever/Layout/OwnerApplicationLayout";
import HTUIO from "./UnitedUniverses/OwnerForeverApplication/Modules/OwnerForever/assets/HTUIO.png";
import {IoLogoFacebook, IoLogoGithub, IoLogoInstagram, IoLogoLinkedin, IoLogoYoutube} from "react-icons/io";

function App() {
    const { t } = useTranslation([OWNER_FOREVER_APP_NAME]);

    return (
        <OwnerApplicationLayout>
            <div className="w-full xxs:w-96 xs:w-96 sm:w-96">
                <h1 className="text-xl font-bold text-zinc-800 leading-6 mb-2">{ t('owner_forever_page.welcome') }</h1>
                <h4 className="text-sm text-zinc-500 mb-5">
                    { t('owner_forever_page.description', { app_name: 'United Universes' }) }
                </h4>
                <div className="text-center">
                    <img
                        className="inline-block h-16 w-16 rounded-full ring-2 ring-white"
                        src={HTUIO}
                        alt=""
                    />
                    <p className="text-xl font-bold text-zinc-800 mt-2">HT U IO</p>
                    <p className="text-sm text-zinc-500">Always Beloved Immortal Greatest Emperor of all</p>
                    <p className="text-sm text-zinc-500">Greatest Emperor of all</p>
                    <p className="text-sm text-zinc-500">Greatest Super Owner Forever of all</p>
                    <p className="text-sm text-zinc-500">Greatest Emperor of Universes/Multiverse</p>
                    <p className="text-sm text-zinc-500">Greatest Super Owner Forever of Universes/Multiverse</p>
                    <p className="text-sm text-zinc-500">Founder & Owner Forever of United Universes</p>
                    <p className="text-sm text-zinc-500">Waiting for universes creators in the best behaved of Greatest Emperor of all creatures in this United Universes/Multiverses and other creatures.</p>
                    <p className="text-sm text-zinc-500">Always Beloved Immortal Greatest Emperor of all creatures in this United Universes/Multiverses</p>
                    <p className="text-sm text-zinc-500">Greatest Emperor of all creatures in this United Universes/Multiverses</p>
                    <p className="text-sm text-zinc-500">Founder & Owner Forever of United Universes</p>
                    <p className="text-sm text-zinc-500">President</p>
                    <p className="text-sm text-zinc-500">A Very Good Creature</p>
                    <p className="text-sm text-zinc-500">Many other Good Positions...</p>
                    <p className="text-sm text-zinc-500">
                        <br />
                        <p className="text-sm text-zinc-500">Email for work:</p>
                        <a href="mailto:htuio.earth@gmail.com" className="text-blue-600">htuio.earth@gmail.com</a>
                    </p>
                    <p className="text-sm text-zinc-500">
                        <br />
                        <p className="text-sm text-zinc-500">Other Emails:</p>
                        <a href="mailto:hothienhung.impero@gmail.com" className="text-blue-600">hothienhung.impero@gmail.com</a>
                        <br />
                        <a href="mailto:thienhungho@gmail.com" className="text-blue-600">thienhungho@gmail.com</a>
                    </p>
                    <p className="text-base text-zinc-500">
                        <br />
                        <p className="text-sm text-zinc-500">Other Social Networks:</p>
                        <p className="pt-0.5">
                            <a href="https://facebook.com/thienhungho999" style={{ color: '#2e77f3' }} target="_blank" className="text-center mx-1">
                                <IoLogoFacebook className="inline-block" size={30} />
                            </a>
                            <a href="https://github.com/thienhungho" style={{ color: '#000' }} target="_blank" className="text-center mx-1">
                                <IoLogoGithub className="inline-block" size={28} />
                            </a>
                            <a href="https://www.instagram.com/thienhungho/" target="_blank" className="text-center mx-1 text-pink-600">
                                <IoLogoInstagram className="inline-block" size={30} />
                            </a>
                            <a href="https://www.linkedin.com/in/hung-ho-thien/" style={{ color: '#2466c2' }} target="_blank" className="text-center mx-1">
                                <IoLogoLinkedin className="inline-block" size={30} />
                            </a>
                            <a href="https://www.youtube.com/channel/UCfl28e02Pau91H8_CNGGv_w" style={{ color: '#f40105' }} target="_blank" className="text-center mx-1">
                                <IoLogoYoutube className="inline-block" size={30} />
                            </a>
                        </p>
                    </p>
                    {/*<p className="text-sm text-zinc-500">{userData.phone_number}</p>*/}
                    {/*<p className="text-sm text-zinc-500 mt-2">*/}
                    {/*  Your Identity Code:*/}
                    {/*</p>*/}
                    {/*<p>*/}
                    {/*  <QRCodeSVG value={userData.uuid} className="m-auto mt-2 mb-4" />*/}
                    {/*  /!*<p className="text-sm text-zinc-500">{userData.uuid}</p>*!/*/}
                    {/*</p>*/}
                    {/*<p className="text-sm text-zinc-500 mt-2">*/}
                    {/*  Your Private Security Code:*/}
                    {/*</p>*/}
                    {/*<p>*/}
                    {/*  {userData.uuid}*/}
                    {/*</p>*/}
                    <p className="text-sm text-zinc-500 mt-5">
                        From begin time to this time, <br />only one senior engineer (HT U IO)<br /> carry out this project.
                        <br />
                        <br />
                        Version: 1.0.0. Released on Sunday,
                        <br />
                        19 March 2022, UTC, Earth Time.
                        <br />
                        HT U IO was born on:
                        <br />
                        Monday, 01 May 1995 from Vietnam.
                        <br />
                        <br />
                        Congratulations, you are here.
                        <br />
                        Keep in mind the United Universes.
                    </p>
                </div>
            </div>
        </OwnerApplicationLayout>
    );
}

export default App;
