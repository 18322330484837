import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  CONFIGURATION_STATE_NAME,
  ConfigurationObjectType,
  ConfigurationSimpleObject,
  ConfigurationStateType,
} from '../Types/ConfigurationTypes';

const initialState: ConfigurationStateType = {
  data: [] as ConfigurationObjectType[],
  simple_data: {} as ConfigurationSimpleObject,
};

export const configurationSlice = createSlice({
  name: CONFIGURATION_STATE_NAME,
  initialState,
  reducers: {
    setConfigurationData: (state, action: PayloadAction<ConfigurationObjectType[]>) => {
      state.data = action.payload;
    },
    setSimpleConfigurationData: (state, action: PayloadAction<ConfigurationSimpleObject>) => {
      state.simple_data = action.payload;
    },
  },
});

export const { setConfigurationData, setSimpleConfigurationData } = configurationSlice.actions;

export default configurationSlice.reducer;
