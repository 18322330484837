import {
    EARTH_MANAGEMENT_APP_NAME,
    EARTH_MANAGEMENT_APP_SUB_DOMAIN,
    EARTH_AUTHENTICATION_APP_NAME,
    EARTH_AUTHENTICATION_APP_SUB_DOMAIN,
    EARTH_DONATION_APP_NAME,
    EARTH_DONATION_APP_SUB_DOMAIN,
    EARTH_APP_NAME,
    EARTH_APP_SUB_DOMAIN,
    MAIN_APP_NAME,
    EARTH_SOCIAL_APP_NAME,
    EARTH_SOCIAL_APP_SUB_DOMAIN,
    EARTH_SPONSOR_APP_NAME,
    EARTH_SPONSOR_APP_SUB_DOMAIN,
} from "../../../ENV/UnitedUniversesENV";
import {v4 as uuidv4} from 'uuid';
import {store} from "../../../store";
import * as CryptoJS from 'crypto-js';
import HttpServices from '../../HttpPackage/Services/HttpServices';
import TimeServices from '../../TimePackage/Services/TimeServices';
import {setCurrentApplicationData} from '../Slice/applicationSlice';
import CookiesServices from "../../CookiesPackage/Services/CookiesServices";
import {CURRENT_FRONT_END_REQUESTER_INFORMATION_PART_KEY__COOKIES_KEY} from "../Types/ApplicationTypes";

const {dispatch} = store;

class ApplicationServices {
    getAndStoreCurrentApplicationName = () => {
        const currentSubDomain = HttpServices.getCurrentSubDomain();

        if (currentSubDomain === EARTH_APP_SUB_DOMAIN) {
            dispatch(setCurrentApplicationData({
                current_app_code: EARTH_APP_NAME,
                current_planet_app_code: EARTH_APP_NAME,
            }));

            return EARTH_APP_NAME;
        }

        if (currentSubDomain === EARTH_AUTHENTICATION_APP_SUB_DOMAIN) {
            dispatch(setCurrentApplicationData({
                current_app_code: EARTH_AUTHENTICATION_APP_NAME,
                current_planet_app_code: EARTH_APP_NAME,
            }));

            return EARTH_AUTHENTICATION_APP_NAME;
        }

        if (currentSubDomain === EARTH_MANAGEMENT_APP_SUB_DOMAIN) {
            dispatch(setCurrentApplicationData({
                current_app_code: EARTH_MANAGEMENT_APP_NAME,
                current_planet_app_code: EARTH_APP_NAME,
            }));

            return EARTH_MANAGEMENT_APP_NAME;
        }

        if (currentSubDomain === EARTH_SPONSOR_APP_SUB_DOMAIN) {
            dispatch(setCurrentApplicationData({
                current_app_code: EARTH_SPONSOR_APP_NAME,
                current_planet_app_code: EARTH_APP_NAME,
            }));

            return EARTH_SPONSOR_APP_NAME;
        }

        if (currentSubDomain === EARTH_SOCIAL_APP_SUB_DOMAIN) {
            dispatch(setCurrentApplicationData({
                current_app_code: EARTH_SOCIAL_APP_NAME,
                current_planet_app_code: EARTH_APP_NAME,
            }));

            return EARTH_SOCIAL_APP_NAME;
        }

        if (currentSubDomain === EARTH_DONATION_APP_SUB_DOMAIN) {
            dispatch(setCurrentApplicationData({
                current_app_code: EARTH_DONATION_APP_NAME,
                current_planet_app_code: EARTH_APP_NAME,
            }));

            return EARTH_DONATION_APP_NAME;
        }

        dispatch(setCurrentApplicationData({
            current_app_code: MAIN_APP_NAME,
            current_planet_app_code: EARTH_APP_NAME,
        }));

        return MAIN_APP_NAME;
    };

    getCurrentPlanetApplicationSubdomain = () => {
        return HttpServices.getCurrentSubDomain().split('.')[0];
    }

    getCurrentPlanetApplicationName = () => {
        const currentApplicationSubdomain = this.getCurrentPlanetApplicationSubdomain();

        if (currentApplicationSubdomain === EARTH_APP_SUB_DOMAIN) {
            dispatch(setCurrentApplicationData({
                current_app_code: EARTH_APP_NAME,
                current_planet_app_code: EARTH_APP_NAME,
            }));

            return EARTH_APP_NAME;
        }

        return MAIN_APP_NAME;
    };

    getCurrentPlanetSubdomain = () => HttpServices.getCurrentPlanetDomain();

    isDevMode = () => !process.env.NODE_ENV || process.env.NODE_ENV === 'development';

    getCopyrightText = (appName: string) => {
        if (appName) {
            return `${appName} © ${TimeServices.renderCurrentYearByCurrentUserTimeZone()}`;
        }

        return `United Universes © ${TimeServices.renderCurrentYearByCurrentUserTimeZone()}`;
    };

    // eslint-disable-next-line default-param-last
    getVersionText = (versionString = '1.7.0', releasedAt = '20 March 2022') => `Version: ${versionString}. Released on ${releasedAt}.`;

    getHTUIOInfinityOwnerForeverText = () => {
        const ownerForeverHref = '/owner-forever';

        return `Founder & Owner Forever By <a href="${ownerForeverHref}" class="text-blue-500">HT U IO (Thiên Hùng).</a><br /> Based on founder's experiences & many hours of working time.<br /> Working, Defense, No war forever, Don't be bad.`;
    };

    getCurrentFrontEndRequesterInformationPartKey = () => {
        if (CookiesServices.has(CURRENT_FRONT_END_REQUESTER_INFORMATION_PART_KEY__COOKIES_KEY)) {
            return CookiesServices.get(CURRENT_FRONT_END_REQUESTER_INFORMATION_PART_KEY__COOKIES_KEY);
        } else {
            const key = CryptoJS.MD5(TimeServices.getCurrentUTCTimeToSendToBackend() + uuidv4()).toString();

            CookiesServices.set(CURRENT_FRONT_END_REQUESTER_INFORMATION_PART_KEY__COOKIES_KEY, key);

            return key;
        }
    }
}

export default new ApplicationServices();
