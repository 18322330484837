import Joi from "joi";
import FormUtilsServices from "../../FormUtilsPackage/Services/FormUtilsServices";

export interface LimitTheNumberOfUsersAnalyticsObjectType {
  uuid: string,
  total_users: number|string,
}

export interface UserManagementStateType {
  limit_the_number_of_users: LimitTheNumberOfUsersAnalyticsObjectType;
}

export const USER_MANAGEMENT_STATE_NAME = 'user_management';

export interface UserActivatedEasyToRememberPathFormParams {
  easy_to_remember_path: string,
  original_easy_to_remember_path: string,
}

export const UserActivatedEasyToRememberPathFormParamsSchema = Joi.object().options({ abortEarly: true }).keys({
  easy_to_remember_path: FormUtilsServices.stringRule().required(),
  original_easy_to_remember_path: FormUtilsServices.stringRule().required(),
});
