import Joi from "joi";
import FormUtilsServices from "../../FormUtilsPackage/Services/FormUtilsServices";

export interface UserNameObjectType {
    uuid: string,
    user_uuid: string,
    first_name: string,
    last_name: string,
    display_name_type_uuid: string,
    is_primary_name: boolean,
    is_secondary_name: boolean,
}

export interface UserNameStateType {
    data: UserNameObjectType[];
}

export const USER_NAMES_MANAGEMENT_STATE_NAME = 'user_names';

export interface MyUserNamesFormParams {
    first_name: string,
    last_name: string,
    display_name_type_uuid: string,
    is_primary_name: boolean,
    is_secondary_name: boolean,
}

export interface UserNamesFormParams extends MyUserNamesFormParams {
    user_uuid: string,
}

const UserNamesFormParamsWithoutUUIDSchemaKeys = {
    first_name: FormUtilsServices.stringRule().required(),
    last_name: FormUtilsServices.stringRule().required(),
    display_name_type_uuid: FormUtilsServices.stringRule().required(),
    is_primary_name: FormUtilsServices.booleanRule().required(),
    is_secondary_name: FormUtilsServices.booleanRule().required(),
};

export const MyUserNamesFormParamsSchema = Joi.object().options({ abortEarly: true }).keys(
    UserNamesFormParamsWithoutUUIDSchemaKeys
);

export const UserNamesFormParamsSchema = Joi.object().options({ abortEarly: true }).keys({
    ...UserNamesFormParamsWithoutUUIDSchemaKeys,
    ...{
        user_uuid: FormUtilsServices.stringRule().required(),
    }
});

// export interface UpsertProfileInformationFormParams {
//     // user_jobs: ,
//     // user_avatar: ,
//     user_names: ProfileNamesFormParams[],
//     // user_date_of_birth: ,
//     // user_profile_cover_media: ,
// }
