import _ from "lodash";
import Cookies from "universal-cookie";
import {CookieSetOptions} from "universal-cookie/cjs/types";
import TimeServices from "../../TimePackage/Services/TimeServices";
import {DOMAINS} from "../../ApplicationManagementPackage/Types/ApplicationTypes";
import EncryptionServices from "../../EncryptionPackage/Services/AESEncryptionServices";
import {ERROR_APPLICATION_PATH} from "../../../ErrorApplication/Modules/Error/Const/URL";

class CookiesServices {
    beforeAnyActionsEvent = () => {
        if (!this.isDeviceSupportCookies()) {
            window.location.href = `/${ERROR_APPLICATION_PATH.DEVICE_DOES_NOT_SUPPORT_COOKIES}`;
        }
    }

    /**
     * @param keyName
     */
    has = (keyName: string) => {
        this.beforeAnyActionsEvent();

        const cookies = new Cookies();
        const cookiesValue = cookies.get(keyName);

        return !_.isEmpty(cookies.get(keyName)) && !_.isUndefined(cookiesValue);
    }

    /**
     * @param keyName
     * @param value
     * @param expiredAfterMinutes
     * @param httpOnly
     */
    set = (
        keyName: string,
        value: string,
        expiredAfterMinutes: number|CookieSetOptions['expires'] = 1440,
        httpOnly: boolean = false,
    ) => {
        this.beforeAnyActionsEvent();

        const cookies = new Cookies();

        let expires = expiredAfterMinutes;
        if (typeof expires == 'number') {
            expires = TimeServices.currentUTCTime().add(expires, 'minute').toDate();
        }

        const cookiesValue = EncryptionServices.encrypt({ data: JSON.stringify({ value }) });

        // for (let i = 0; i < DOMAINS.length; i++) {
        //     cookies.set(keyName, cookiesValue, {
        //         path: '/',
        //         domain: `${DOMAINS[i]}`,
        //         sameSite: 'lax',
        //         expires,
        //         httpOnly,
        //     });
        // }

        cookies.set(keyName, cookiesValue, {
            path: '/',
            domain: `.uniteduniverses.org`,
            sameSite: 'lax',
            expires,
            httpOnly,
        });
    }

    /**
     * @param keyName
     */
    get = (keyName: string) => {
        this.beforeAnyActionsEvent();

        const cookies = new Cookies();

        if (!this.has(keyName)) {
            return null;
        }

        return EncryptionServices.decrypt({ data: cookies.get(keyName) })?.value;
    }

    /**
     * @param keyName
     */
    remove = (keyName: string) => {
        this.beforeAnyActionsEvent();

        const cookies = new Cookies();

        cookies.set(keyName, null, {
            path: '/',
            domain: `.uniteduniverses.org`,
            sameSite: 'lax',
            expires: TimeServices.currentUTCTime().add(-5, 'minute').toDate(),
        });

        // for (let i = 0; i < DOMAINS.length; i++) {
        //     cookies.set(keyName, null, {
        //         path: '/',
        //         domain: `${DOMAINS[i]}`,
        //         sameSite: 'lax',
        //         expires: TimeServices.currentUTCTime().add(-5, 'minute').toDate(),
        //     });
        // }
    }

    removeAll = () => {
        this.beforeAnyActionsEvent();

        document.cookie = '';
    }

    /**
     * @param keyName
     */
    isCookiesValueIsNull = (keyName: string) => {
        this.beforeAnyActionsEvent();

        const cookies = new Cookies();

        return _.isEmpty(EncryptionServices.decrypt({ data: cookies.get(keyName) }));
    }

    isDeviceSupportCookies = () => navigator.cookieEnabled;

    /**
     * @param reason
     */
    refreshCookies = (reason: string) => {
        let doAction = false;
        if (!this.has('unitedUniversesRC')) {
            doAction = true;
        }

        if (this.has('unitedUniversesRC')) {
            if (JSON.parse(this.get('unitedUniversesRC')).reason !== reason) {
                doAction = true;
            }
        }

        if (doAction) {
            this.removeAll();

            this.set(
                'unitedUniversesRC',
                JSON.stringify(
                    {
                        'refresh_cookie_at': TimeServices.getCurrentUTCTimeAtISOString(),
                        'reason': reason,
                    }
                ),
                1440000000000000000
            );
        }
    }
}

export default new CookiesServices();
