import { SPONSOR_PATH } from '../Packages/SponsorPackage/Config/URL';
import { DONATION_PATH } from '../Packages/DonationPackage/Config/URL';
import { AUTHENTICATION_PATH } from '../Packages/AuthenticationPackage/Config/URL';
import { ERROR_APPLICATION_PATH } from "../ErrorApplication/Modules/Error/Const/URL";
import { OWNER_FOREVER_PATH } from '../OwnerForeverApplication/Modules/OwnerForever/Const/URL';
import ApplicationServices from '../Packages/ApplicationManagementPackage/Services/ApplicationServices';
import {
    RUN_BACK_END_SOURCE_CODE_LOG_APPLICATION_PATH
} from "../RunBackendSourceCodeLogApplication/Modules/RunBackendSourceCodeLogApplication/Const/URL";
import { MAINTENANCE_PATH } from "../MaintenanceApplication/Modules/Maintenance/Const/URL";

export const I0VZV = '4rkUHEuBdsQHTUIO';
export const K0VZE0VZY = 'zXBQlQ9EPcfKb18LqZGsEpThWbqHTUIO';

export const IUVZV = 'qxkBDfaicQPHTUIO';
export const KZVZE0VZY = 'IvlXMVyrr1jIJHQSRuTwQDKeudEHTUIO';

export const DOUBLE_U_TYPE_TWO_ENCRYPTION_KEY = '999388687345828HTUIO';

export const BACKEND_URL = 'http://local.api.uniteduniverses.org:8000';
// export const BACKEND_URL = 'https://eapo.uniteduniverses.org';

export const REQUEST_HEADER_APPROVAL_APPLICATION_FLAG_KEY = 'Identity-Application';

export const NEED_ENCRYPT_REQUEST_PARAMS_FIELD_KEY_FLAG_KEY = 'UnitedUniverses_need_encrypt_params';

export const NEED_DECRYPT_KEY_REQUEST_PARAMS_FIELD_KEY_FLAG_KEY = 'UnitedUniverses_need_decrypt_key_params';

export const BOOLEAN_PARAMS = 'UnitedUniverses_boolean_params';

export const RESPONSE_ENCRYPTED_HEADER_FLAG_KEY = 'Response-Encrypted';

export const COMMON_APP_NAME = 'common';

export const MAIN_APP_SUB_DOMAIN = '';

export const MAIN_APP_NAME = 'main';

export const OWNER_FOREVER_APP_NAME = 'ownerForever';

export const ERROR_APP_NAME = 'error';

export const MAINTENANCE_APP_NAME = 'maintenance';

export const UPDATING_APP_NAME = 'updating';

export const EARTH_APP_SUB_DOMAIN = 'earth';

export const EARTH_APP_NAME = 'earth';

export const EARTH_AUTHENTICATION_APP_SUB_DOMAIN = 'earth-authentication';

export const EARTH_AUTHENTICATION_APP_NAME = 'earth-authentication';

export const EARTH_MANAGEMENT_APP_SUB_DOMAIN = ApplicationServices.isDevMode() ? 'earth-local.management' : 'earth.management';

export const EARTH_MANAGEMENT_APP_NAME = 'management';

export const EARTH_SOCIAL_APP_SUB_DOMAIN = ApplicationServices.isDevMode() ? 'earth-local.social' : 'earth.social';

export const EARTH_SOCIAL_APP_NAME = 'social';

export const EARTH_SPONSOR_APP_SUB_DOMAIN = ApplicationServices.isDevMode() ? 'earth-local.sponsor' : 'earth.sponsor';

export const EARTH_SPONSOR_APP_NAME = 'sponsor';

export const EARTH_DONATION_APP_SUB_DOMAIN = ApplicationServices.isDevMode() ? 'earth-local.donation' : 'earth.donation';

export const EARTH_DONATION_APP_NAME = 'donation';

export const EARTH_NOTIFICATION_APP_SUB_DOMAIN = ApplicationServices.isDevMode() ? 'earth-local.notification' : 'earth.notification';

export const EARTH_NOTIFICATION_APP_NAME = 'notification';

export const GUEST_URLS = [
    SPONSOR_PATH.SPONSOR_RANKING,
    SPONSOR_PATH.SPONSOR_HISTORIES,
    DONATION_PATH.DONATION_RANKING,
    DONATION_PATH.DONATION_HISTORIES,
    OWNER_FOREVER_PATH.OWNER_FOREVER,
    AUTHENTICATION_PATH.LOGIN,
    AUTHENTICATION_PATH.REGISTER,
    AUTHENTICATION_PATH.RECOVERY_PASSWORD,
    ERROR_APPLICATION_PATH.NOT_FOUND,
    ERROR_APPLICATION_PATH.TOO_MANY_REQUEST,
    RUN_BACK_END_SOURCE_CODE_LOG_APPLICATION_PATH.TRACKING_SERVER,
    MAINTENANCE_PATH.MAINTENANCE,
];
