import { EARTH_APP_SUB_DOMAIN } from "../../../ENV/UnitedUniversesENV";

class HttpServices {
    getSubDomain = (hostname: string | undefined) => hostname?.split('.').slice(0, -2).join('.');

    getCurrentSubDomain = () => window.location.hostname.split('.').slice(0, -2).join('.');

    getCurrentDomain = () => window.location.hostname.split('.').slice(-2).join('.');

    getCurrentFullDomainWithProtocol = () => `${this.getCurrentProtocol()}//${window.location.hostname}`;

    getCurrentDomainWithProtocol = () => `${this.getCurrentProtocol()}//${window.location.hostname.split('.').slice(-2).join('.')}`;

    getCurrentProtocol = () => window.location.protocol;

    getCurrentPort = () => window.location.port;

    getCurrentPathName = () => window.location.pathname;

    // TODO: Edit in the future when connect to other planets.......
    getCurrentPlanetDomain = () => {
        const fullDomain = this.getCurrentFullDomainWithProtocol();

        return fullDomain.replace(this.getCurrentSubDomain(), EARTH_APP_SUB_DOMAIN);
    }

    // TODO: Edit in the future when connect to other planets.......
    getCurrentPlanetSubdomain = () => {
        const currentPlanetDomain = this.getCurrentPlanetDomain();
        let subDomain = this.getSubDomain(currentPlanetDomain) as string;
        subDomain = subDomain.replace('https://', '');
        subDomain = subDomain.replace('http://', '');

        return subDomain;
    }
}

export default new HttpServices();
