import * as CryptoJS from 'crypto-js';
import _ from 'lodash';
import {I0VZV, K0VZE0VZY} from "../../../ENV/UnitedUniversesENV";

class AESEncryptionServices {
    getIV = () => CryptoJS.enc.Utf8.parse(I0VZV);

    getKey = () => CryptoJS.enc.Utf8.parse(K0VZE0VZY);

    /**
     * @param data
     * @param jsonDecode
     */
    decrypt = ({data, jsonDecode = true}: { data: string, jsonDecode?: boolean }) => {
        if (typeof data === 'object') {
            return data;
        }

        let result = CryptoJS.AES.decrypt(data, this.getKey(), {
            iv: this.getIV(),
            mode: CryptoJS.mode.CBC,
        }).toString(CryptoJS.enc.Utf8);

        if (jsonDecode && !_.isEmpty(result)) {
            return JSON.parse(result);
        }

        return result;
    };

    /**
     * @param data
     * @param jsonDecode
     */
    encrypt = ({data}: { data: string }) => CryptoJS.AES.encrypt(data, this.getKey(), {
        iv: this.getIV(),
        mode: CryptoJS.mode.CBC,
    }).toString();
}

export default new AESEncryptionServices();
