import Joi from "joi";
import FormUtilsServices from "../../FormUtilsPackage/Services/FormUtilsServices";
import {UserObjectType} from "../../AuthenticationPackage/Types/AuthenticationTypes";

export interface ProfileStateTypeObjectType extends UserObjectType {

}

export interface ProfileManagementStateType {
    my_profile: ProfileStateTypeObjectType;
    profile_of_other: ProfileStateTypeObjectType;
}

export const PROFILE_MANAGEMENT_STATE_NAME = 'profile_management';

export interface ProfileSecurityInformationFormParams {
    current_password: string,
    password: string,
    password_confirmation: string,
}

export const ProfileSecurityInformationFormParamsSchema = Joi.object().options({ abortEarly: true }).keys({
    current_password: FormUtilsServices.stringRule().required(),
    // TODO: Password and password confirmation must be the same.
    password: FormUtilsServices.stringRule().required(),
    password_confirmation: FormUtilsServices.stringRule().required(),
});

export interface ProfileUserNamesFormParams {
    user_uuid: string,
    first_name: string,
    last_name: string,
    display_name_type_uuid: string,
    is_primary_name: boolean,
    is_secondary_name: boolean,
}

export const ProfileUserNamesFormParamsSchema = Joi.object().options({ abortEarly: true }).keys({
    user_uuid: FormUtilsServices.stringRule().required(),
    first_name: FormUtilsServices.stringRule().required(),
    last_name: FormUtilsServices.stringRule().required(),
    display_name_type_uuid: FormUtilsServices.stringRule().required(),
    is_primary_name: FormUtilsServices.booleanRule().required(),
    is_secondary_name: FormUtilsServices.booleanRule().required(),
});

// export interface UpsertProfileInformationFormParams {
//     // user_jobs: ,
//     // user_avatar: ,
//     user_names: ProfileNamesFormParams[],
//     // user_date_of_birth: ,
//     // user_profile_cover_media: ,
// }
