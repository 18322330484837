import {initReactI18next} from 'react-i18next';
import i18next from 'i18next';
import {
    MAIN_APP_NAME,
    ERROR_APP_NAME,
    EARTH_SOCIAL_APP_NAME,
    EARTH_SPONSOR_APP_NAME,
    OWNER_FOREVER_APP_NAME,
    EARTH_DONATION_APP_NAME,
    EARTH_AUTHENTICATION_APP_NAME,
    EARTH_APP_NAME,
    MAINTENANCE_APP_NAME,
    EARTH_MANAGEMENT_APP_NAME,
} from "../../ENV/UnitedUniversesENV";
import mainEN from './locales/en/main.json';
import commonEN from './locales/en/common.json';
import socialEN from './locales/en/social.json';
import sponsorEN from './locales/en/sponsor.json';
import ownerForeverEN from './locales/en/ownerForever.json';
import donationEN from './locales/en/donation.json';
import errorEN from './locales/en/error.json';
import earthEN from './locales/en/earth.json';
import maintenanceEN from './locales/en/maintenance.json';
import managementEN from './locales/en/management.json';
import authenticationEN from './locales/en/authentication.json';

i18next
    .use(initReactI18next)
    .init({
        lng: 'en',
        fallbackLng: 'en',
        ns: [
            'common',
            EARTH_AUTHENTICATION_APP_NAME,
            EARTH_SPONSOR_APP_NAME,
            EARTH_SOCIAL_APP_NAME,
            MAIN_APP_NAME,
            OWNER_FOREVER_APP_NAME,
            EARTH_DONATION_APP_NAME,
            ERROR_APP_NAME,
            EARTH_APP_NAME,
            EARTH_MANAGEMENT_APP_NAME,
        ],
        interpolation: {
            escapeValue: false,
        },
        resources: {
            en: {
                common: commonEN,
                [EARTH_AUTHENTICATION_APP_NAME]: authenticationEN,
                [EARTH_SPONSOR_APP_NAME]: sponsorEN,
                [EARTH_SOCIAL_APP_NAME]: socialEN,
                [MAIN_APP_NAME]: mainEN,
                [OWNER_FOREVER_APP_NAME]: ownerForeverEN,
                [EARTH_DONATION_APP_NAME]: donationEN,
                [ERROR_APP_NAME]: errorEN,
                [EARTH_APP_NAME]: earthEN,
                [MAINTENANCE_APP_NAME]: maintenanceEN,
                [EARTH_MANAGEMENT_APP_NAME]: managementEN,
            },
        },
    });
