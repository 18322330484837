import LogoLight from './assets/logo-light.png';
import LogoDark from './assets/logo-dark.png';
import LogoDarkIcon from './assets/logo-dark-icon.png';

function Logo({
  className,
  type = 'colored',
}: {
    className: string,
    type: 'light'|'dart'|'colored'|'simple',
}) {
  const getLogoSRC = () => {
    if (type === 'light') {
        return LogoLight;
    }

    if (type === 'dart') {
        return LogoDark;
    }

    if (type === 'simple') {
        return LogoDarkIcon;
    }

    return LogoLight;
  };

  return (
    <div className={className}>
      <a href="/">
        <img
          src={getLogoSRC()}
          alt="United Universes Logo"
          className="drop-shadow-2xl"
        />
      </a>
    </div>
  );
}

export default Logo;
