import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {MODAL_STATE_NAME, ModalObjectType, ModalStateType} from '../Types/ModalTypes';

const initialState: ModalStateType = {
    data: [] as ModalObjectType[],
};

export const modalSlice = createSlice({
    name: MODAL_STATE_NAME,
    initialState,
    reducers: {
        setModalData: (state: { data: ModalObjectType[]; }, action: PayloadAction<ModalObjectType[]>) => {
            state.data = action.payload;
        },
        addModalData: (state: { data: ModalObjectType[]; }, action: PayloadAction<ModalObjectType>) => {
            state.data.push(action.payload);
        },
        removeModal: (state: { data: any[]; }, action: PayloadAction<ModalObjectType['key']>) => {
            const index = state.data.map((modalData) => modalData.key).indexOf(action.payload);

            state.data.splice(index, 1);
        },
        removeModals: (state: { data: any[]; }, action: PayloadAction<ModalObjectType['key'][]>) => {
            const index = state.data.map((modalData) => modalData.key).indexOf(action.payload);

            state.data.splice(index, 1);
        },
    },
});

export const {setModalData, addModalData, removeModal} = modalSlice.actions;

export default modalSlice.reducer;
