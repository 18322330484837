import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {
    ProfileManagementStateType,
    ProfileStateTypeObjectType,
    PROFILE_MANAGEMENT_STATE_NAME,
} from "../Types/ProfileManagementTypes";

const initialState: ProfileManagementStateType = {
    profile_of_other: {} as ProfileStateTypeObjectType,
    my_profile: {} as ProfileStateTypeObjectType,
};

export const profileManagementSlice = createSlice({
    name: PROFILE_MANAGEMENT_STATE_NAME,
    initialState,
    reducers: {
        setProfileOfOther: (state, action: PayloadAction<ProfileStateTypeObjectType>) => {
            state.profile_of_other = action.payload;
        },
        setMyProfile: (state, action: PayloadAction<ProfileStateTypeObjectType>) => {
            state.my_profile = action.payload;
        },
    },
});

export const {setProfileOfOther, setMyProfile} = profileManagementSlice.actions;

export default profileManagementSlice.reducer;
