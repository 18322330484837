export interface ModalObjectType {
    key: string,
    size: number|string,
    title: string,
    description: string|JSX.Element|null,
    onClose: () => void,
    centerTitle: boolean,
    centerDescription: boolean,
    footer: string|JSX.Element|null,
    children: string|JSX.Element|null,
    type: 'success'|'alert'|'danger'|'input'|'normal',
    showReloadPageButton?: boolean,
}

export interface ModalStateType {
    data: ModalObjectType[];
}

// eslint-disable-next-line import/prefer-default-export
export const MODAL_STATE_NAME = 'modal';
