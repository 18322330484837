import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {
    USER_NAMES_MANAGEMENT_STATE_NAME,
    UserNameObjectType,
    UserNameStateType
} from "../Types/UserNameManagementTypes";

const initialState: UserNameStateType = {
    data: [] as UserNameObjectType[],
};

export const userNameManagementSlice = createSlice({
    name: USER_NAMES_MANAGEMENT_STATE_NAME,
    initialState,
    reducers: {
        setCurrentUserNames: (state, action: PayloadAction<UserNameObjectType[]>) => {
            state.data = action.payload;
        },
        addUserName: (state: { data: UserNameObjectType[]; }, action: PayloadAction<UserNameObjectType>) => {
            state.data.push(action.payload);
        },
        removeUserName: (state: { data: any[]; }, action: PayloadAction<UserNameObjectType['uuid']>) => {
            const index = state.data.map((userName) => userName.uuid).indexOf(action.payload);

            state.data = state.data.splice(index, 1);
        },
    },
});

export const {setCurrentUserNames, addUserName, removeUserName} = userNameManagementSlice.actions;

export default userNameManagementSlice.reducer;
