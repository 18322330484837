export const MAINTENANCE_STATE_NAME = 'maintenance';

export interface MaintenanceStateObjectType {
  beStillContinue: boolean,
  beenBegan: boolean,
  beenEnded: boolean,
  begin_at: string,
  created_at: string,
  end_at: string,
  title: string,
}

export interface MaintenanceStateType {
  data: MaintenanceStateObjectType;
}
