export interface FormErrorType {
    [key: string]: string[]
}

export interface FormErrorStateType {
    errors: {
        [key: string]: FormErrorType
    };
}

export interface ValidateFormDataFunctionType {
    data: any,
    schema: any,
    messageTranslateKey: string,
    alsoValidateEmptyData: boolean,
    onValidateSuccess?: (errors: FormErrorType|null) => void,
}

export const FORM_ERROR_STATE_NAME = 'form_error';
