import './index.scss';
import App from './App';
import React from 'react';
import i18next from 'i18next';
import {Provider} from 'react-redux';
import * as Sentry from "@sentry/react";
import {createRoot} from 'react-dom/client';
import {I18nextProvider} from 'react-i18next';
import {store} from './UnitedUniverses/store';
import {BrowserRouter} from 'react-router-dom';
import {BrowserTracing} from "@sentry/tracing";
import reportWebVitals from './reportWebVitals';
import './UnitedUniverses/Packages/MultipleLanguagePackage/i18n';

const container = document.getElementById('root')!;
const root = createRoot(container);

Sentry.init({
    dsn: "https://4b634040acab4af88d8c750968d100b3@o322486.ingest.sentry.io/4504208838754304",
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
});

root.render(
    <Provider store={store}>
        <BrowserRouter>
            <I18nextProvider i18n={i18next}>
                <App />
            </I18nextProvider>
        </BrowserRouter>
    </Provider>,
);

// If you want to start measuring performance in your UnitedUniverses, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
