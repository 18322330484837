import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FORM_ERROR_STATE_NAME, FormErrorStateType } from '../Types/FormUtilsTypes';

const initialState: FormErrorStateType = {
  errors: {} as FormErrorStateType['errors'],
};

export const formErrorSlice = createSlice({
  name: FORM_ERROR_STATE_NAME,
  initialState,
  reducers: {
    setFormErrors: (state, action: PayloadAction<FormErrorStateType['errors']>) => {
      state.errors = action.payload;
    },
  },
});

export const { setFormErrors } = formErrorSlice.actions;

export default formErrorSlice.reducer;
